@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --h1-size: 4rem;
  --h2-size: 3rem;
  --h1-weight: 400;
  --h2-weight: 400;
  --font-family: 'Helvetica', 'Arial', sans-serif;
}

.gradient {
  background: linear-gradient(90deg, #1f33ac 0%, #1a84ee 100%);
}

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}

.active.fade-left {
  animation: fade-left 1s ease-in;
}

.active.fade-right {
  animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animation-ctn {
  text-align: center;
  /* margin-top:5em; */
}

@-webkit-keyframes checkmark {
  0% {
    stroke-dashoffset: 100px
  }

  100% {
    stroke-dashoffset: 200px
  }
}

@-ms-keyframes checkmark {
  0% {
    stroke-dashoffset: 100px
  }

  100% {
    stroke-dashoffset: 200px
  }
}

@keyframes checkmark {
  0% {
    stroke-dashoffset: 100px
  }

  100% {
    stroke-dashoffset: 0px
  }
}

@-webkit-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 480px
  }

  100% {
    stroke-dashoffset: 960px;

  }
}

@-ms-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px
  }

  100% {
    stroke-dashoffset: 480px
  }
}

@keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 480px
  }

  100% {
    stroke-dashoffset: 960px
  }
}

@keyframes colored-circle {
  0% {
    opacity: 0
  }

  100% {
    opacity: 100
  }
}

/* other styles */
/* .svg svg {
      display: none
  }
   */
.inlinesvg .svg svg {
  display: inline
}

/* .svg img {
      display: none
  } */

.icon--order-success svg polyline {
  -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
  animation: checkmark 0.25s ease-in-out 0.7s backwards
}

.icon--order-success svg circle {
  -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
  animation: checkmark-circle 0.6s ease-in-out backwards;
}

.icon--order-success svg circle#colored {
  -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
  animation: colored-circle 0.6s ease-in-out 0.7s backwards;
}

.circle {
  stroke-dasharray: 480px, 480px;
  stroke-dashoffset: 960px;
}

.polyline {
  stroke-dasharray: 100px, 100px;
  stroke-dashoffset: 200px;
}
.circle {
  width: calc(100vw/6);
  height: calc(100vw/6);
  border-radius: 50%;
}
